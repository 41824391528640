import React from "react";
import "./style.css";
import { LandingLink } from "../landingLink/landingLink";
import { LandingCard } from "../landingCard/landingCard";

const LandingAccordion = ({ data, infoState, setInfoState }) => {
	const accordionData = Object.values(data);

	return (
		<div id="accordion">
			{accordionData.map((item, idx) => (
				<div class="accordionCard" key={idx}>
					<div id={`heading-${idx}`}>
						<h5 class="mb-0">
							<div
								data-toggle="collapse"
								data-target={`#collapse-${idx}`}
								aria-expanded={"false"}
								aria-controls={`collapse-${idx}`}
							>
								<LandingCard
									id={item.id}
									infoState={infoState}
									setInfoState={setInfoState}
									route={`#${item.hash_id}`}
								>
									{item.title}
								</LandingCard>
							</div>
						</h5>
					</div>

					<div
						id={`collapse-${idx}`}
						class={`collapse`}
						aria-labelledby={`heading-${idx}`}
						data-parent="#accordion"
					>
						<div class="accordionDetails">
							<h6>{item?.descriptionTitle}</h6>
							<h5>{item?.descriptionBody}</h5>
							<ul>
								{item?.listItem?.map((item, index) => (
									<li key={index}>{item}</li>
								))}
							</ul>
							{item?.links?.map(({ linkTitle, route }, index) => (
								<LandingLink key={index} route={route}>
									{linkTitle}
								</LandingLink>
							))}
						</div>
					</div>
				</div>
			))}
		</div>
	);
};

export default LandingAccordion;
