import "./landingCard.css";
import { useLocation, NavLink } from "react-router-dom";
import { useEffect, useState } from "react";

export const LandingCard = ({
	infoState,
	setInfoState,
	route,
	id,
	children
}) => {
	const location = useLocation();
	const [activeHash, setActiveHash] = useState(location.hash);
	useEffect(() => {
		setActiveHash(location.hash);
		if (activeHash === route) {
			setInfoState(id);
		}
	}, [activeHash, id, location.hash, route, setInfoState]);

	return (
		<NavLink
			to={route}
			className={`landing_card_container ${
				infoState === id && "active-card-background"
			}`}
			onClick={() => {
				setInfoState(id);
			}}
		>
			{infoState === id ? <div className="active-card" /> : null}
			<h4 className="d-inline">{children}</h4>
		</NavLink>
	);
};
