import { generateUrlParams } from "../../utils/generateUrlParams";

const baseUrl = "JupebApplication";

export const verifyJupebApplicationStatusUrl = (rrr) =>
	`${baseUrl}/verify-application-status/${rrr}`;
export const previewJupebApplicationUrl = (rrr) =>
	`${baseUrl}/verify-and-load-jupeb-application-form/${rrr}`;
export const addAndUpdateJupebProfile = () =>
	`${baseUrl}/add-or-update-jupeb-application-form`;
export const addAndUpdateSponsorAndRefereeUrl = () =>
	`${baseUrl}/add-or-update-jupeb-application-sponsor-and-referee`;
export const addAndUpdateOLevelUrl = () =>
	`${baseUrl}/add-or-update-jupeb-application-olevel-details`;
export const uploadDocumentsUrl = (applicantId) =>
	`${baseUrl}/upload-documents/${applicantId}`;
export const getAllJupebApplicationsUrl = (filter) =>
	`${baseUrl}/all-jupeb-applications?${generateUrlParams(filter)}`;
export const changeApplicationStatusUrl = (applicationNo) =>
	`${baseUrl}/admit-jupeb-applicant?applicationNo=${applicationNo}`;
