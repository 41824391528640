import React from "react";
import { useHistory } from "react-router-dom";
// import logo from "../../assets/images/sideLogo.png";
import loginLogo from "../../assets/svgs/loginLogo.svg";
import christmasLogo from "../../assets/images/christmasLogoSingle.png";
import ChristmasLight from "../../assets/images/bannerLight.png";
import styles from "./auth_style.module.css";
import { checkIfChristmasPeriod } from "../../utils/checkIfChristmasPeriod";

export default function AuthPageWrapper({ children }) {
	const { push } = useHistory();
	return (
		<div
			className={`${styles.auth_container} d-flex justify-content-center text-center align-items`}
		>
			<div className={`${styles.auth_card} bg-white`}>
				{checkIfChristmasPeriod() && (
					<div className={styles.christmasLightContainer}>
						<img
							src={ChristmasLight}
							alt="chrsitmas Light"
							className={`${styles.light}`}
						/>
					</div>
				)}
				<div className={`mt-5 pt-2 ${styles.logo}`}>
					{checkIfChristmasPeriod() ? (
						<>
							<img src={christmasLogo} alt="futo logo" />
						</>
					) : (
						<>
							<img
								src={checkIfChristmasPeriod() ? christmasLogo : loginLogo}
								alt="Login Logo"
								className={`${styles.loginLogo}`}
								onClick={() => push("/")}
							/>
						</>
					)}
				</div>
				{children}
			</div>
		</div>
	);
}
