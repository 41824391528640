import { useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useApiGet } from "../../../api/apiCall";
import { previewJupebApplicationUrl } from "../../../api/urls";
import { Spinner } from "../../../ui_elements";
import { ApplicationPreviewWrapper } from "../ApplicationPreviewWrapper";
import styles from "./style.module.css";

const JupebApplicationPreview = () => {
	const { push } = useHistory();
	const { state } = useLocation();
	const componentRef = useRef();
	if (!state) push("/jupeb_login");

	const { data, isLoading, error } = useApiGet(
		previewJupebApplicationUrl(state?.details),
		{
			refetchOnWindowFocus: false
		}
	);

	const subjectAndGrade = data?.data?.olevelResponse?.map((item) =>
		Object?.entries(item?.subjectGrade)
	);

	if (isLoading) return <Spinner />;
	if (error) return "An error has occurred: " + error.message;

	return (
		<ApplicationPreviewWrapper
			previewHeader={`${data?.data?.basicInformation?.session} JUPEB Application Slip`}
			userDetails={{
				fullname: `${data?.data?.basicInformation?.lastname} ${data?.data?.basicInformation?.firstname} `,
				passport: data?.data?.basicInformation?.passport
			}}
			componentRef={componentRef}
		>
			<div className={`${styles.preview_container} p-4`}>
				<section className="row align-items-center mt-3">
					<h4 className="mb-2">Personal Information</h4>
					<div className="row align-items-center justify-content-between  px-4">
						<div className="col-12 col-md-6 my-2">
							<div className="row align-items-center my-3">
								<h6 className="col-3">Application No</h6>
								<p className="col-8">
									{data?.data?.basicInformation
										?.applicationNumber || "-"}
								</p>
							</div>
							<div className="row align-items-center my-3">
								<h6 className="col-3">Full Name</h6>
								<p className="col-8">
									{`${data?.data?.basicInformation?.lastname} ${data?.data?.basicInformation?.firstname} ${data?.data?.basicInformation?.middlename}`}
								</p>
							</div>
							<div className="row align-items-center my-3">
								<h6 className="col-3">Maiden Name</h6>
								<p className="col-8">
									{`${data?.data?.basicInformation?.maidenName}`}
								</p>
							</div>

							<div className="row align-items-center my-3">
								<h6 className="col-3">Sex</h6>
								<p className="col-8">
									{data?.data?.basicInformation?.gender ||
										"-"}
								</p>
							</div>
							<div className="row align-items-center my-3">
								<h6 className="col-3">Date of birth</h6>
								<p className="col-8">
									{data?.data?.basicInformation?.dateOfBirth?.split(
										"T"
									)[0] || "-"}
								</p>
							</div>
							<div className="row align-items-center my-3">
								<h6 className="col-3">Country</h6>
								<p className="col-8">
									{data?.data?.basicInformation?.country ??
										"-"}
								</p>
							</div>
							<div className="row align-items-center my-3">
								<h6 className="col-3">State of origin</h6>
								<p className="col-8">
									{data?.data?.basicInformation?.state || "-"}
								</p>
							</div>
							<div className="row align-items-center my-3">
								<h6 className="col-3">LGA of origin</h6>
								<p className="col-8">
									{data?.data?.basicInformation?.lga || "-"}
								</p>
							</div>
						</div>
						<div className="col-12 col-md-6 my-2">
							<div className="row align-items-center my-3">
								<h6 className="col-3">Email address</h6>
								<p className="col-8">
									{data?.data?.basicInformation?.email || "-"}
								</p>
							</div>
							<div className="row align-items-center my-3">
								<h6 className="col-3">Mobile phone</h6>
								<p className="col-8">
									{data?.data?.basicInformation
										?.mobileNumber || "-"}
								</p>
							</div>
							<div className="row align-items-center my-3">
								<h6 className="col-3">Address</h6>
								<p className="col-8">
									{data?.data?.basicInformation
										?.permanentAddress || "-"}
								</p>
							</div>
							<div className="row align-items-center my-3">
								<h6 className="col-3">Religion</h6>
								<p className="col-8">
									{data?.data?.basicInformation?.religion ||
										"-"}
								</p>
							</div>
						</div>
						<div className="col-6 my-2"></div>
					</div>
				</section>
				<section className="row">
					<section className="col-12 col-md-6">
						<div className="row align-items-center mt-3">
							<h4 className="mb-2">Sponsor Details</h4>
							<div className="row align-items-center justify-content-between px-4">
								<div className="my-2">
									<div className="row align-items-center my-3">
										<h6 className="col-3">Fullname</h6>
										<p className="col-8">
											{data?.data?.sponsor?.fullname ||
												"-"}
										</p>
									</div>
									<div className="row align-items-center my-3">
										<h6 className="col-3">Email address</h6>
										<p className="col-8">
											{data?.data?.sponsor?.email || "-"}
										</p>
									</div>
									<div className="row align-items-center my-3">
										<h6 className="col-3">Mobile number</h6>
										<p className="col-8">
											{data?.data?.sponsor
												?.mobileNumber || "-"}
										</p>
									</div>
									<div className="row align-items-center my-3">
										<h6 className="col-3">Address</h6>
										<p className="col-8">
											{data?.data?.sponsor?.address ||
												"-"}
										</p>
									</div>
									<div className="row align-items-center my-3">
										<h6 className="col-3">Relationship</h6>
										<p className="col-8">
											{data?.data?.sponsor
												?.relationship || "-"}
										</p>
									</div>
								</div>
							</div>
						</div>
					</section>
					<section className="col-12 col-md-6 ">
						<div className="row align-items-center mt-3">
							<h4 className="mb-2">Referee Details</h4>
							<div className="row align-items-center justify-content-between">
								<div className="my-2">
									<div className="row align-items-center my-3">
										<h6 className="col-3">Fullname</h6>
										<p className="col-8">
											{data?.data?.referee?.fullname ||
												"-"}
										</p>
									</div>
									<div className="row align-items-center my-3">
										<h6 className="col-3">Email address</h6>
										<p className="col-8">
											{data?.data?.referee?.email || "-"}
										</p>
									</div>
									<div className="row align-items-center my-3">
										<h6 className="col-3">Mobile number</h6>
										<p className="col-8">
											{data?.data?.referee
												?.mobileNumber || "-"}
										</p>
									</div>
									<div className="row align-items-center my-3">
										<h6 className="col-3">Address</h6>
										<p className="col-8">
											{data?.data?.referee?.address ||
												"-"}
										</p>
									</div>
								</div>
							</div>
						</div>
					</section>
				</section>
				<section className="row align-items-center mt-3">
					<div className="col-12 col-md-6">
						<h4 className="mb-2">Programme Details</h4>
						<div className="row align-items-center justify-content-between px-2">
							<div className="row align-items-center my-3">
								<h6 className="col-3">Subject Combination</h6>
								<p className="col-8">
									{data?.data?.basicInformation
										?.jupebOptionSubject || "-"}
								</p>
							</div>
							<div className="row align-items-center my-3">
								<h6 className="col-3">Application No</h6>
								<p className="col-8">
									{data?.data?.basicInformation
										?.applicationNumber || "-"}
								</p>
							</div>
							<div className="row align-items-center my-3">
								<h6 className="col-3">Program type</h6>
								<p className="col-8">
									{data?.data?.applicationType ||
										"JUPEB Application"}
								</p>
							</div>
						</div>
					</div>
				</section>
				<section className="row align-items-center mt-3">
					<h4 className="mb-2">O-Level Result</h4>
					<div className="row align-items-center justify-content-between  px-4">
						{data?.data?.olevelResponse &&
							data?.data?.olevelResponse?.map((item, index) => (
								<div
									className="col-12 col-md-6 my-2"
									key={index}
								>
									<h5>
										{index === 0
											? "First Sitting"
											: "Second Sitting"}
									</h5>
									<div className="row align-items-center my-3">
										<h6 className="col-3">Exam Type</h6>
										<p className="col-8">
											{item?.examinationType ?? "-"}
										</p>
									</div>
									<div className="row align-items-center my-3">
										<h6 className="col-3">Exam Number</h6>
										<p className="col-8">
											{item?.examNumber ?? "-"}
										</p>
									</div>
									<div className="row align-items-center my-3">
										<h6 className="col-3">Exam Year</h6>
										<p className="col-8">
											{item?.examYear ?? "-"}
										</p>
									</div>
									<div className="row align-items-center my-3">
										<h6 className="col-3">Exam Center</h6>
										<p className="col-8">
											{item?.examCenter ?? "-"}
										</p>
									</div>
									<div className="row align-items-center  my-4">
										<div className="row align-items-center my-3">
											<h5 className="col-9 p-0 m-0">
												Subject
											</h5>
											<h5 className="col-3 p-0 m-0">
												Grade
											</h5>
										</div>
										{subjectAndGrade &&
											subjectAndGrade?.[index]?.map(
												(item, index) => (
													<div
														key={index}
														className="row align-items-center my-2"
													>
														<p className="col-9 col-sm-7 text-capitalize">
															{item?.[0]}
														</p>
														<p className="col-3 col-sm-3">
															{item?.[1]}
														</p>
													</div>
												)
											)}
									</div>
								</div>
							))}
					</div>
				</section>
			</div>
		</ApplicationPreviewWrapper>
	);
};

export default JupebApplicationPreview;
