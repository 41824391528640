import { useContext, useState } from "react";
import logo from "../../assets/images/sideLogo.png";
import globalMenuIcon from "../../assets/svgs/globalMenuIcon.svg";
import cancel from "../../assets/svgs/cancel.svg";
import Avatar from "react-avatar";
import "./globalMenu.css";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { Button } from "../button/Button";
import { checkIfChristmasPeriod } from "../../utils/checkIfChristmasPeriod";
import ChristmasLogo from "../../assets/images/christmasLogo.png";
import { StudentProfileContext } from "..";
import useComponentVisible from "../../custom-hooks/useComponentIsVisible";
import useHandleNotification from "../../custom-hooks/useHandleNotification";
import { useApiGet } from "../../api/apiCall";
import { getAllRecentNotificationUrl } from "../../api/urls";
import { Bell } from "../../assets/svgs";
import { Spinner } from "..";
import { timeAgo } from "../../utils/formatDate";


const GlobalMenu = ({
	title = "Components",
	openSide = () => { },
	setSignOutModal,
	userName,
	isLanding
}) => {
	const { push } = useHistory();
	const [menu, setMenu] = useState(false);
	const { ref, isComponentVisible, setIsComponentVisible } =
		useComponentVisible(false);
	const { handNotificationAction } = useHandleNotification();
	

	const linkOptions = [
		{
			name: "Home",
			route: "/"
		},
		{
			name: "Sundry payments",
			route: "/sundry/all"
		},
		{
			name: "Check Admission Status",
			route: "/prospective_students"
		}
	];

	const {
		data: recentNotifications,
		isLoading: isLoadingRecentNotifications
	} = useApiGet(getAllRecentNotificationUrl(), {
		enabled: true,
		keepPreviousData: true,
		refetchOnWindowFocus: false
	});

	let recentUnreadNotifications = recentNotifications?.data?.filter(
		({ isRead }) => !isRead
	)?.length;

	const data = useContext(StudentProfileContext);

	return (
		<section className="red-global-menu">
			<div className="d-flex align-items-center">
				{isLanding !== "unAuthenticated" ? (
					<button className="red-hamburger-menu" onClick={openSide}>
						<img src={globalMenuIcon} alt="open side menu" />
					</button>
				) : (
					<button
						className="red-hamburger-menu responsive-hamburger-menu"
						onClick={() => setMenu(true)}
					>
						<img src={globalMenuIcon} alt="open side menu" />
					</button>
				)}
				<div className="red-global-logo">
					{checkIfChristmasPeriod() ? (
						<>
							<img src={ChristmasLogo} alt="futo logo" />
							<img src={ChristmasLogo} alt="futo logo" />
							{/* <img src={logo} alt="futo logo" />
							<img src={logo} alt="futo logo" /> */}
						</>
					) : (
						<>
							<img src={logo} alt="futo logo" />
							<img src={logo} alt="futo logo" />
						</>
					)}
				</div>
				{isLanding === "unAuthenticated" ? (
					<div
						className={`red-current-module global-landing-page-link-container ${menu && `retract-navigation`
							}`}
					>
						<button
							onClick={() => setMenu(false)}
							className="cancel-button"
						>
							<img src={cancel} alt="" />
						</button>

						{linkOptions.map((link, i) => (
							<button
								key={i}
								className="global-landing-page-links"
								onClick={() => push(link.route)}
							>
								{link.name}
							</button>
						))}
					</div>
				) : (
					<div className="red-current-module">{title}</div>
				)}
			</div>
			{isLanding !== "unAuthenticated" ? (
				<div className="d-flex dropdown">
					{userName ? (
						<section
							className="gm-notification-container"
							ref={ref}
						>
							<button
								onClick={() =>
									setIsComponentVisible(!isComponentVisible)
								}
							>
								<Bell />
								{recentUnreadNotifications ? (
									<span className="gm-notification-breathing-light">
										{recentUnreadNotifications}
									</span>
								) : (
									""
								)}
							</button>
							<div
								ref={ref}
								className={`gm-notification-contents gm-notification-contents-style ${isComponentVisible ? "d-block" : "d-none"
									}`}
							>
								<div className="d-flex justify-content-between align-items-center border-bottom px-3 pt-3">
									<h3>
										Notifications(
										{recentNotifications?.data?.length})
									</h3>
									<Link
										to="/notifications"
										className="gm-view-all"
									>
										View All Notifications
									</Link>
								</div>
								<div className="gm-notification-items pt-3">
									{isLoadingRecentNotifications ? (
										<Spinner />
									) : (
										<ul>
											{recentNotifications?.data?.map(
												(item, index) => (
													<li
														key={index}
														className={`py-2 border-bottom px-3 ${item?.isRead
															? "gm-notification-read"
															: ""
															}`}
														role="button"
														onClick={() =>
															handNotificationAction(
																item
															)
														}
													>
														<div className="pr-4">
															<h4>
																{
																	item?.notificationType
																}
															</h4>
															<p className="my-1">
																{item?.message}
															</p>
															<p>
																{timeAgo(
																	item?.dateCreated
																)}
															</p>
														</div>
													</li>
												)
											)}
										</ul>
									)}
									<div className="py-4 text-center">
										That's all your notifications from the
										last 7 days.
									</div>
								</div>
							</div>
						</section>
					) : (
						""
					)}

					<div className="dropdown">
						<section
							id="navDrop"
							data-toggle="dropdown"
							role="button"
							data-cy="open_avatat"
						>
							<Avatar
								name={userName ?? "Guest"}
								round
								size="32"
								color="var(--primary)"
								className="global-avatar"
								maxInitials={2}
								src={data?.profileData?.personalData?.passport}
							/>
						</section>
						<section className="dropdown-menu res-role">
							<h3 className="res-user">
								{userName?.toUpperCase() ?? "guest"}
							</h3>
							<Link
								data-cy="profile"
								className="mb-0 res-role-txt"
								to="/profile"
							>
								Profile
							</Link>

							<section className="res-wrapper">
								<h3 className="res-user">Settings</h3>
								<div className="d-flex align-items-center justify-content-between res-roles">
									<div
										className="d-flex align-items-center"
										onClick={() => push("/change_password")}
									>
										<p className="mb-0 mx-0">Change Password</p>
									</div>
								</div>
							</section>

							<section>
								<p
									className="mb-0 res-role-txt mt-3"
									onClick={() => setSignOutModal(true)}
								>
									Logout
								</p>
							</section>
						</section>
					</div>
				</div>

			) : (
				<Button
					onClick={() => push("/login")}
					data-cy="default"
					buttonClass="primary"
					label="Login"
				/>
			)}
		</section>
	);
};

export { GlobalMenu };
