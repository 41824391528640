import { INITIAL_DATE } from "../../utils/constants";
import { formatDateFromAPI } from "../../utils/formatDate";
import { SAVE_SUPPLEMENTARY_INFO } from "../constant";

export const supplementaryInitialState = (data) => ({
	nameRegNoConfirmation: {},
	StudentTypeId: data?.studentTypeId,
	StudentType: data?.studentType,
	passport: {
		passport: data?.passport
	},
	programmeInfo: {
		regNo: data?.regNumber,
		utmeScore: data?.programmeInfoResponse?.utmeScore,
		faculty: {
			label: data?.programmeInfoResponse?.faculty,
			value: data?.programmeInfoResponse?.facultyId
		},
		facultyShoppingInto: {
			label: data?.programmeInfoResponse?.facultyShoppingInto,
			value: data?.programmeInfoResponse?.facultyShoppingIntoId
		},
		department: {
			label: data?.programmeInfoResponse?.department,
			value: data?.programmeInfoResponse?.departmentId
		},
		departmentOption: {
			label: data?.programmeInfoResponse?.departmentOption,
			value: data?.programmeInfoResponse?.departmentOptionId
		},
		courseShoppingInto: {
			label: data?.programmeInfoResponse?.courseShoppingInto,
			value: data?.programmeInfoResponse?.courseShoppingIntoId
		},
		supplementaryApplicationDate:
			data?.programmeInfoResponse?.supplementaryApplicationDate,
		rrr: data?.personalInfoResponse?.rrr
	},
	personalInfo: {
		firstName: data?.personalInfoResponse?.firstname,
		middleName: data?.personalInfoResponse?.middlename,
		surName: data?.personalInfoResponse?.surname,
		...(data?.personalInfoResponse?.dateOfBirth && {
			dateOfBirth:
				data?.personalInfoResponse?.dateOfBirth === INITIAL_DATE
					? ""
					: formatDateFromAPI(data?.personalInfoResponse?.dateOfBirth)
		}),
		...(data?.personalInfoResponse?.gender && {
			sex: {
				label: data?.personalInfoResponse?.gender,
				value: data?.personalInfoResponse?.genderId
			}
		}),
		mobileNo: data?.personalInfoResponse?.mobileNumber,
		email: data?.personalInfoResponse?.email,
		contactAddress: data?.personalInfoResponse?.contactAddress,

		...(data?.personalInfoResponse?.country && {
			country: {
				label: data?.personalInfoResponse?.country,
				value: data?.personalInfoResponse?.countryId
			}
		}),
		...(data?.personalInfoResponse?.state && {
			state: {
				label: data?.personalInfoResponse?.state,
				value: data?.personalInfoResponse?.stateId
			}
		}),
		...(data?.personalInfoResponse?.lga && {
			lga: {
				label: data?.personalInfoResponse?.lga,
				value: data?.personalInfoResponse?.lgaId
			}
		}),
		rrr: data?.personalInfoResponse?.rrr
	}
});

export const supplementaryReducer = (state = {}, action) => {
	switch (action.type) {
		case SAVE_SUPPLEMENTARY_INFO:
			return action.payload;
		default:
			return state;
	}
};
