import {
	Jumbotron,
	Button,
	TextField,
	SMSelect
} from "../../../../ui_elements";
import { useLocation, useHistory } from "react-router";
import { Controller, useForm } from "react-hook-form";
import { useApiPut } from "../../../../api/apiCall";
import { yupResolver } from "@hookform/resolvers/yup";
import { addAndUpdateSponsorAndRefereeUrl } from "../../../../api/urls";
import { sponsorAndRefereeSchema } from "../jupebApplicationSchema";
import { useDispatch, useSelector } from "react-redux";
import { JUPEP_APPLICATIONS } from "../../../../store/constant";

export const SponsorAndReferee = ({ relationships }) => {
	const jupebStoreData = useSelector((state) => state.jupebData);
	const dispatch = useDispatch();
	const { replace } = useHistory();
	const { state } = useLocation();

	if (!state) {
		replace("/jupeb_login");
	}

	const { mutate, isLoading: isFormLoading } = useApiPut();

	const {
		register,
		control,
		handleSubmit,
		formState: { errors }
	} = useForm({
		defaultValues: {
			sponsorFullName: jupebStoreData?.sponsorAndReferee?.sponsorFullName,
			sponsorAddress: jupebStoreData?.sponsorAndReferee?.sponsorAddress,
			sponsorMobileNo: jupebStoreData?.sponsorAndReferee?.sponsorMobileNo,
			sponsorEmail: jupebStoreData?.sponsorAndReferee?.sponsorEmail,
			sponsorRelationship:
				jupebStoreData?.sponsorAndReferee?.sponsorRelationship,
			refereeFullName: jupebStoreData?.sponsorAndReferee?.refereeFullName,
			refereeAddress: jupebStoreData?.sponsorAndReferee?.refereeAddress,
			refereeMobileNo: jupebStoreData?.sponsorAndReferee?.refereeMobileNo,
			refereeEmail: jupebStoreData?.sponsorAndReferee?.refereeEmail
		},
		resolver: yupResolver(sponsorAndRefereeSchema)
	});

	const onSubmit = (sponsorAndReferee) => {
		if (!jupebStoreData?.passport?.passport) {
			window.scrollTo(0, 0);
			const errorFlag = window.AJS.flag({
				type: "error",
				title: "Invalid Action!",
				body: "Please, upload your profile picture before you can proceed!"
			});
			setTimeout(() => {
				errorFlag.close();
			}, 5000);
			return;
		}

		const requestBody = {
			url: addAndUpdateSponsorAndRefereeUrl(),
			data: {
				ApplicantId: jupebStoreData?.Id,
				Sponsor: {
					Fullname: sponsorAndReferee?.sponsorFullName,
					Address: sponsorAndReferee?.sponsorAddress,
					MobileNumber: sponsorAndReferee?.sponsorMobileNo,
					Email: sponsorAndReferee?.sponsorEmail,
					RelationshipId:
						sponsorAndReferee?.sponsorRelationship?.value
				},
				Referee: {
					Fullname: sponsorAndReferee?.refereeFullName,
					Address: sponsorAndReferee?.refereeAddress,
					MobileNumber: sponsorAndReferee?.refereeMobileNo,
					Email: sponsorAndReferee?.refereeEmail
				}
			}
		};
		mutate(requestBody, {
			onSuccess: () => {
				const successFlag = window.AJS.flag({
					type: "success",
					title: "Successfully updated sponsor and referee details",
					body: "You can now proceed to next step"
				});
				setTimeout(() => {
					successFlag.close();
				}, 5000);
				dispatch({
					type: JUPEP_APPLICATIONS,
					payload: {
						...jupebStoreData,
						sponsorAndReferee
					}
				});
				replace({ hash: "#section_c", state });
			},
			onError: () => {
				const errorFlag = window.AJS.flag({
					type: "error",
					title: "Failed!",
					body: "Something went wrong"
				});
				setTimeout(() => {
					errorFlag.close();
				}, 5000);
			}
		});
	};
	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<Jumbotron
				headerText="Sponsor Details"
				footerContent={
					<Button
						data-cy="submit_personal"
						label="Next"
						buttonClass="primary"
						type="submit"
						loading={isFormLoading}
					/>
				}
				footerStyle="d-flex justify-content-end"
			>
				<div className="container-fluid px-4 mt-4 mb-3">
					<div className="row">
						<div className="col-lg-3 d-flex align-items-center">
							<label htmlFor="sponsorFullName">
								{" "}
								Sponsor's Fullname
							</label>
						</div>
						<div className="col-lg-9">
							<TextField
								id="sponsorFullName"
								autoComplete="off"
								placeholder="Enter sponsor full name"
								className="w-100"
								type="text"
								name="sponsorFullName"
								register={register}
								error={errors.sponsorFullName}
								errorText={
									errors.sponsorFullName &&
									errors.sponsorFullName.message
								}
							/>
						</div>
					</div>
				</div>
				<div className="container-fluid px-4 my-3">
					<div className="row">
						<div className="col-lg-3">
							<label htmlFor="sponsorAddress">
								Sponsor's Address
							</label>
						</div>
						<div className="col-lg-9">
							<TextField
								id="sponsorAddress"
								autoComplete="off"
								placeholder="Enter sponsor address"
								className="w-100"
								inputType="textarea"
								name="sponsorAddress"
								register={register}
								error={errors.sponsorAddress}
								errorText={
									errors.sponsorAddress &&
									errors.sponsorAddress.message
								}
							/>
						</div>
					</div>
				</div>
				<div className="container-fluid px-4 my-3">
					<div className="row">
						<div className="col-lg-3 d-flex align-items-center">
							<label htmlFor="sponsorEmail">
								Sponsor's Email
							</label>
						</div>
						<div className="d-flex col-lg-9">
							<TextField
								id="sponsorEmail"
								className="w-100"
								placeholder="Enter sponsor email"
								type="text"
								name="sponsorEmail"
								register={register}
								error={errors.sponsorEmail}
								errorText={
									errors.sponsorEmail &&
									errors.sponsorEmail.message
								}
							/>
						</div>
					</div>
				</div>
				<div className="container-fluid px-4 my-3">
					<div className="row">
						<div className="col-lg-3 d-flex align-items-center">
							<label htmlFor="sponsorMobileNo">
								Sponsor's Mobile No
							</label>
						</div>
						<div className="d-flex col-lg-9">
							<TextField
								id="sponsorMobileNo"
								className="w-100"
								placeholder="Enter sponsor phone number"
								type="text"
								name="sponsorMobileNo"
								register={register}
								error={errors.sponsorMobileNo}
								errorText={
									errors.sponsorMobileNo &&
									errors.sponsorMobileNo.message
								}
							/>
						</div>
					</div>
				</div>
				<div className="container-fluid px-4 mt-4 mb-5">
					<div className="row">
						<div className="col-lg-3  d-flex align-items-center">
							<label htmlFor="sponsorRelationship">
								Relationship
							</label>
						</div>
						<div className="col-lg-9">
							<Controller
								name="sponsorRelationship"
								control={control}
								render={({ field }) => (
									<SMSelect
										{...field}
										placeholder="Choose relationship"
										options={relationships}
										searchable={false}
										id="sponsorRelationship"
										isError={!!errors.sponsorRelationship}
										errorText={
											errors.sponsorRelationship &&
											errors.sponsorRelationship.message
										}
									/>
								)}
							/>
						</div>
					</div>
				</div>
				<div className="border-top border-bottom px-4 py-3 jumbotron-header jumbo-header">
					<span>Referee Details</span>
				</div>
				<div className="container-fluid px-4 mt-4 mb-3">
					<div className="row">
						<div className="col-lg-3 d-flex align-items-center">
							<label htmlFor="refereeFullName">
								Referee's Fullname
							</label>
						</div>
						<div className="col-lg-9">
							<TextField
								id="refereeFullName"
								autoComplete="off"
								placeholder="Enter referee full name"
								className="w-100"
								type="text"
								name="refereeFullName"
								register={register}
								error={errors.refereeFullName}
								errorText={
									errors.refereeFullName &&
									errors.refereeFullName.message
								}
							/>
						</div>
					</div>
				</div>
				<div className="container-fluid px-4 my-3">
					<div className="row">
						<div className="col-lg-3">
							<label htmlFor="refereeAddress">
								Referee's Address
							</label>
						</div>
						<div className="col-lg-9">
							<TextField
								id="refereeAddress"
								autoComplete="off"
								placeholder="Enter referee address"
								className="w-100"
								inputType="textarea"
								name="refereeAddress"
								register={register}
								error={errors.refereeAddress}
								errorText={
									errors.refereeAddress &&
									errors.refereeAddress.message
								}
							/>
						</div>
					</div>
				</div>
				<div className="container-fluid px-4 my-3">
					<div className="row">
						<div className="col-lg-3 d-flex align-items-center">
							<label htmlFor="refereeEmail">
								Referee's Email
							</label>
						</div>
						<div className="d-flex col-lg-9">
							<TextField
								id="refereeEmail"
								className="w-100"
								placeholder="Enter referee email"
								type="text"
								name="refereeEmail"
								register={register}
								error={errors.refereeEmail}
								errorText={
									errors.refereeEmail &&
									errors.refereeEmail.message
								}
							/>
						</div>
					</div>
				</div>
				<div className="container-fluid px-4 my-3">
					<div className="row">
						<div className="col-lg-3 d-flex align-items-center">
							<label htmlFor="refereeMobileNo">
								{" "}
								Referee's Mobile No
							</label>
						</div>
						<div className="d-flex col-lg-9">
							<TextField
								id="refereeMobileNo"
								className="w-100"
								placeholder="Enter referee phone number"
								type="text"
								name="refereeMobileNo"
								register={register}
								error={errors.refereeMobileNo}
								errorText={
									errors.refereeMobileNo &&
									errors.refereeMobileNo.message
								}
							/>
						</div>
					</div>
				</div>
			</Jumbotron>
		</form>
	);
};
