import * as yup from "yup";
import {
	checkForCorrectPhoneNumber,
	checkifDuplicateEntriesExist,
	checkIfMinimumNumberOfSubjectIsSelected,
	checkIfUserIsLessThanMaximumAge,
	checkIfUserIsMoreThanMinimumAge,
	checkforValidName,
	checkIfValidFullName
} from "../../../utils/formValidations";

export const personalDetailsSchema = yup.object().shape({
	TitleId: yup.mixed().required("please select your title"),
	Surname: yup
		.string()
		.test(
			"text name",
			"invalid name, check for trailing spaces",
			checkforValidName
		)
		.nullable(),
	Firstname: yup
		.string()
		.test(
			"text name",
			"invalid name, check for trailing spaces",
			checkforValidName
		)
		.nullable(),
	Middlename: yup
		.string()
		.test(
			"text name",
			"invalid name, check for trailing spaces",
			checkforValidName
		)
		.nullable(),
	GenderId: yup.mixed().required("please select your gender"),
	DateOfBirth: yup
		.string()
		.required("please input your date of birth")
		.nullable()
		.test(
			"min age",
			"you have to be more than 15 years old",
			checkIfUserIsMoreThanMinimumAge
		)
		.test(
			"max age",
			"you have to be less than 80 years old",
			checkIfUserIsLessThanMaximumAge
		),
	CountryId: yup.mixed().required("please select your country"),
	StateId: yup.mixed().required("please select your state"),
	LgaId: yup.mixed().when("$isLGARequired", (isLGARequired, schema) => {
		if (isLGARequired) {
			return schema.required("please select your LGA");
		}
		return schema.default(null);
	}),
	PermanentAddress: yup
		.string()
		.required("please enter contact address")
		.nullable(),
	MobileNo: yup
		.string()
		.required("phone number is required")
		.test(
			"text number",
			"invaild phone number",
			checkForCorrectPhoneNumber
		),
	Email: yup.string().email("invalid email address").nullable(),
	Disability: yup
		.string()
		.when("$isDisabilityRequired", (isDisabilityRequired, schema) => {
			if (isDisabilityRequired)
				return schema.required("please enter your disability");
			return schema.default(null);
		})
		.nullable(),
	ReligionId: yup.mixed().required("please select your religion"),
	DepartmentId: yup.mixed().required("please select your school"),
	JupebOptionSubjectId: yup
		.mixed()
		.required("please select your subject combination")
});

export const OlevelResultSchema = yup.object().shape({
	sittings: yup
		.array()
		.of(
			yup.object().shape({
				examinationType: yup
					.mixed()
					.required("please select your o level type"),
				examCenter: yup
					.string()
					.required("please enter your exam center"),
				examNo: yup.string().required("please enter your exam number"),
				examYear: yup.mixed().required("please select your exam year"),
				cardPin: yup.string().required("please enter your card pin"),
				cardSerialNumber: yup
					.string()
					.required("please enter your card serial number"),
				subjects: yup
					.array()
					.of(
						yup.object().shape({
							subject: yup.mixed(),
							grade: yup.mixed()
						})
					)
					.test(
						"incomplete",
						"subjects are required",
						checkIfMinimumNumberOfSubjectIsSelected
					)
					.test(
						"duplicate",
						"duplicate entries exists",
						checkifDuplicateEntriesExist
					)
					.required("please select at least one subject")
			})
		)
		.required("this information is required")
});

export const sponsorAndRefereeSchema = yup.object().shape({
	sponsorFullName: yup
		.string()
		.required("full name is required")
		.test(
			"test first & last name",
			"enter both first and last name",
			checkIfValidFullName
		)
		.nullable(),
	sponsorAddress: yup
		.string()
		.required("please input next of kin's address")
		.nullable(),
	sponsorMobileNo: yup
		.string()
		.required("phone number is required")
		.test("text number", "invaild phone number", checkForCorrectPhoneNumber)
		.nullable(),
	sponsorEmail: yup
		.string()
		.email("invalid email address")
		.required("please input sponsor email address")
		.nullable(),
	sponsorRelationship: yup
		.mixed()
		.required("please select next of kin's relationship"),
	refereeFullName: yup
		.string()
		.required("full name is required")
		.test(
			"test first & last name",
			"enter both first and last name",
			checkIfValidFullName
		)
		.nullable(),
	refereeAddress: yup
		.string()
		.required("please input next of kin's address")
		.nullable(),
	refereeMobileNo: yup
		.string()
		.required("phone number is required")
		.test("text number", "invaild phone number", checkForCorrectPhoneNumber)
		.nullable(),
	refereeEmail: yup
		.string()
		.email("invalid email address")
		.required("please input referee email address")
		.nullable()
});
