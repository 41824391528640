import styles from "./auth_style.module.css";
import Backdrop from "../../assets/images/auth.webp"

export const AuthPageBackgroundWrapper = ({ children }) => {
	return (
		<div className={`d-flex ${styles.background}`}>
			<div className={`${styles.white}`} />
			<img
				src={Backdrop}
				alt={"login_imgae"}
			/>
			{children}
		</div>
	);
};
