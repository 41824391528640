import { generateUrlParams } from "../../utils/generateUrlParams";

const baseUrl = "Lecturer";

export const getLecturerCoursesUrl = (filter) =>
	`${baseUrl}/lecturer-courses-assigned?${generateUrlParams(filter)}`;

export const getAdviserClassListUrl = (filter) =>
	`${baseUrl}/get-course-adviser-student?${generateUrlParams(filter)}`;

export const downloadAdviserClassListUrl = (filter) =>
	`${baseUrl}/download-course-adviser-student-report?${generateUrlParams(
		filter
	)}`;
