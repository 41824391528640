export function checkIfFilesAreTooBig(files, limit) {
	let valid = true;
	if (files[0]) {
		const file = files[0];
		const sizeInMB = file.size / 1024 / 1024; // Convert size to megabytes
		if (sizeInMB > 2) {
			// Check if size exceeds 2MB
			valid = false;
		}
	}
	return valid;
}

export function checkIfFilesAreCorrectType(files, isImage) {
	let valid = true;
	if (files[0]) {
		const file = files[0];
		if (
			![
				"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
			].includes(file.type)
		) {
			valid = false;
		}
	}
	return valid;
}

export function checkIfImagesAreCorrectType(files) {
	let valid = true;
	if (files[0]) {
		const file = files[0];
		if (!["image/png", "image/jpg", "image/jpeg"].includes(file.type)) {
			valid = false;
		}
	}
	return valid;
}
